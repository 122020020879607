const variables = {
  baseUrl: {
    local: "http://localhost:3000",
    development: "https://iamsterdam-website-development.vercel.app",
    test: "https://iamsterdam-website-development.vercel.app",
    acceptance: "https://acceptance.iamsterdam.com",
    production: "https://www.iamsterdam.com",
  },
  cardApiBaseUrl: {
    development: "https://testscanner.cardapi.io/cks",
    test: "https://testscanner.cardapi.io/cks",
    acceptance: "https://scanner.cardapi.io/cks",
    production: "https://scanner.cardapi.io/cks",
  },
  gtmId: {
    local: null,
    test: null,
    development: "GTM-WNMKQQ",
    acceptance: "GTM-WNMKQQ",
    production: "GTM-WNMKQQ",
  },
  recaptchaEnabled: {
    development: false,
    test: true,
    acceptance: true,
    production: true,
  },
  importerBaseUrl: {
    development:
      "https://europe-west1-i-amsterdam-361113.cloudfunctions.net/feed-factory-sync-dev",
    test: "https://europe-west1-i-amsterdam-361113.cloudfunctions.net/feed-factory-sync-dev",
    acceptance:
      "https://europe-west1-i-amsterdam-361113.cloudfunctions.net/feed-factory-sync",
    production:
      "https://europe-west1-i-amsterdam-361113.cloudfunctions.net/feed-factory-sync",
  },
  aws: {
    s3BucketName: {
      development: "iamsterdam-dev",
      test: "iamsterdam-dev",
      acceptance: "iamsterdam",
      production: "iamsterdam",
    },
  },
  salesforce: {
    instanceURL: {
      development: "https://iamsterdam--testap.sandbox.my.salesforce.com",
      test: "https://iamsterdam--testap.sandbox.my.salesforce.com",
      acceptance: "https://iamsterdam.my.salesforce.com",
      production: "https://iamsterdam.my.salesforce.com",
    },
    clientID: {
      development:
        "3MVG9jPQJ8Itx6F.y.apvMEEk60AZK80v8yznluiAeR2gxIqahpUrCMw9L8CWnrVGp9sByTqEWZw6YCVilkp9",
      test: "3MVG9jPQJ8Itx6F.y.apvMEEk60AZK80v8yznluiAeR2gxIqahpUrCMw9L8CWnrVGp9sByTqEWZw6YCVilkp9",
      acceptance:
        "3MVG9fTLmJ60pJ5J2uFzW8u.KapEfZ7XXSM6cOKEIpxfYXCu76Rpq3eUlKyLe6DiAaCNzriYKGNRRtl0m3QnI",
      production:
        "3MVG9fTLmJ60pJ5J2uFzW8u.KapEfZ7XXSM6cOKEIpxfYXCu76Rpq3eUlKyLe6DiAaCNzriYKGNRRtl0m3QnI",
    },
    clientSecret: {
      development: process.env.SALESFORCE_CLIENT_SECRET,
      test: process.env.SALESFORCE_CLIENT_SECRET,
      acceptance: process.env.SALESFORCE_CLIENT_SECRET,
      production: process.env.SALESFORCE_CLIENT_SECRET,
    },
    username: {
      development: process.env.SALESFORCE_USERNAME,
      test: process.env.SALESFORCE_USERNAME,
      acceptance: process.env.SALESFORCE_USERNAME,
      production: process.env.SALESFORCE_USERNAME,
    },
    password: {
      development: process.env.SALESFORCE_PASSWORD,
      test: process.env.SALESFORCE_PASSWORD,
      acceptance: process.env.SALESFORCE_PASSWORD,
      production: process.env.SALESFORCE_PASSWORD,
    },
    quizCampaignId: {
      development: "701bg00000590cDAAQ",
      test: "701bg00000590cDAAQ",
      acceptance: "701W500000SlXZzIAN",
      production: "701W500000SlXZzIAN",
    },
  },
};

module.exports = variables;
